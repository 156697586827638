import React from "react";

function ShowSelect(props) {
    return (
      <select
        id={props.selectBoxId}
        onChange={props.handleDepartmentChange}
        value={props.selectedDepartment}
        className={props.selectBoxClassName}
      >
        <option value=""></option>
        {props.selectBoxOptions.map((option) => (
          <option key={option.name} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    );
  };

export default ShowSelect;