import React from "react";
import "./App.css";

const InfoForClassification = () => {
 
  return (
    <label className="form-label-info">
      <h2>Classificação do insumo</h2>
      <h4>
        1) De acordo com o nome do insumo, faça a classificação.
      </h4>
      <h4>
        2) Por favor siga o padrão estabelecido na aba 'Modelos'. Nele estão descriminados os departamentos, para cada um
        existe descrições específicas.
      </h4>
      <h4>
        3) Se achar que o insumo deve ter outra classificação, clique no botão
        'Adicionar novas atribuições'. 
      </h4>
      <h4>
        4) Caso não exista informação de alguma classificação, deixe em branco. 
      </h4>
      
    </label>
  );
};

export default InfoForClassification;
