import React, { useState } from 'react';
import  "./InfoButton.css";


function InfoButtonDimen() {
  const [showText, setShowText] = useState(false);

  function handleClick() {
    setShowText(!showText);
  }

  return (
    <div className="info-button-container">
      <button className="info-button" onClick={handleClick}>
        !
      </button>
      {showText && <div className="info-text">Verifique qual medida pertence a largura e qual pertence a comprimento. Caso seja um número decimal, coloque o decimal com ponto. Caso a medida seja uma fração, coloque em decimal.</div>}
    </div>
  );
}

export default InfoButtonDimen;