import React, { useState } from 'react';
import  "./InfoButton.css";


function InfoButtonCharac() {
  const [showText, setShowText] = useState(false);

  function handleClick() {
    setShowText(!showText);
  }

  return (
    <div className="info-button-container">
      <button className="info-button" onClick={handleClick}>
        !
      </button>
      {showText && <div className="info-text">O texto a ser colocado na informação de caracteristicas é a informação que aparece no nome do insumo, esse texto 
        não devem incluir o nome de departamento ou a
        descrição.</div>}
    </div>
  );
}

export default InfoButtonCharac;