import React from "react";
import "./styles.css"; 

const FlowChart = () => (
  
  <div className="flowchart-container">    
    <div className="box input">
      <p>Selecione o departamento</p>
    </div>
    <div className="arrow"></div>
    <div className="box input">
      <p>Selecione a descrição</p>
    </div>
    <div className="arrow"></div>
    <div className="box input">
      <p>Insira as características</p>
    </div>
    <div className="arrow"></div>
    <div className="box input">
      <p>Coloque informações de tamanho/unidade</p>
    </div>
    <div className="arrow"></div>
    <div className="box input">
      <p>Adicione Modelo/Cor/Marca (se existir)</p>
    </div>
    <div className="arrow"></div>
    <div className="box output">
      <p>Finalizar</p>
    </div>
  </div>
);

export default FlowChart;
