import React from "react";
import InfoButtonCharac from "./InfoButtonCharac";

function ShowInput(props) {
  return (
    <>
      <label htmlFor={props.selectBoxId} className="form-label">
        {props.selectBoxLabel} {props.selectBoxLabel === 'Características:' && (
          <InfoButtonCharac/>
        )}
      </label>
      <input
        type="text"
        id={props.selectBoxId}
        value={props.selectedInfo}
        onChange={props.onChange}
        className="input-box"
      />
    </>
  );
}

export default ShowInput;
