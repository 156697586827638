const people = [
  { name: "Bianka" },
  { name: "Emmanuel" },
  { name: "Lucas" },
  { name: "Joana" },
  { name: "Marcelo" },
  { name: "Marina" },
  { name: "Poliana" },
  { name: "Rodrigo" },
];

export default people;
