const departments = [
  {
    name: "Abraçadeira",
    descriptions: [
      { name: "Aço", brands: [""] },
      { name: "Cunha", brands: ["GE","GF"] },
      { name: "Lâmpada", brands: [""] },
      { name: "Mangueiras", brands: [""] },
      { name: "Metal", brands: [""] },
      { name: "Nylon", brands: ["Vonder","Hellerman"] },
      { name: "Rosca sem fim", brands: [""] },
      { name: "Tipo B", brands: [""] },
      { name: "Tipo Mangote", brands: [""] },
      { name: "Tipo U", brands: [""] },
      { name: "Tubulação", brands: [""] },
    ],
  },
  {
    name: "Acessórios e produtos de pintura",
    descriptions: [
      { name: "Adesivo", brands: [""] },
      { name: "Aguarrás", brands: [""] },
      { name: "Bandeja", brands: [""] },
      { name: "Broxa", brands: [""] },
      { name: "Catalisador", brands: [""] },
      { name: "Caçamba", brands: [""] },
      { name: "Epóxi", brands: [""] },
      { name: "Esmalte", brands: [""] },
      { name: "Garfo para rolo", brands: [""] },
      { name: "Pincel", brands: [""] },
      { name: "Primer", brands: [""] },
      { name: "Querosene", brands: [""] },
      { name: "Removedor", brands: [""] },
      { name: "Rolo", brands: [""] },
      { name: "Selante", brands: [""] },
      { name: "Solvente", brands: [""] },
      { name: "Textura", brands: [""] },
      { name: "Thinner", brands: [""] },
      { name: "Tinta", brands: [""] },
      { name: "Trincha", brands: [""] },
      { name: "Verniz", brands: [""] },
      { name: "Zarcão", brands: [""] },
    ],
  },
  {
    name: "Acessórios e utensílios gerais para escritório",
    descriptions: [
      { name: "Adesivos", brands: [""] },
      { name: "Agenda", brands: ["Tilibra"] },
      { name: "Alfinete", brands: [""] },
      { name: "Almofada para carimbo", brands: ["Tilibra"] },
      { name: "Apagador", brands: [""] },
      { name: "Aparelho telefônico", brands: ["Intelbrás"] },
      { name: "Apontador", brands: [""] },
      { name: "Bandeja", brands: [""] },
      { name: "Bandeja", brands: [""] },
      { name: "Barbante", brands: [""] },
      { name: "Bloco", brands: [""] },
      { name: "Bobina", brands: [""] },
      { name: "Borracha", brands: [""] },
      { name: "Borrifador", brands: [""] },
      { name: "Brindes", brands: ["Tilibra"] },
      { name: "Cadeado", brands: [""] },
      { name: "Caderno", brands: [""] },
      { name: "Calculadora", brands: [""] },
      { name: "Caneta", brands: [""] },
      { name: "Caneta quadro branco", brands: [""] },
      { name: "Carimbo", brands: [""] },
      { name: "Cesto", brands: ["Tilibra"] },
      { name: "Clips", brands: [""] },
      { name: "Cola", brands: [""] },
      { name: "Copo", brands: [""] },
      { name: "Corrediça", brands: [""] },
      { name: "Corretivo", brands: [""] },
      { name: "Dispenser", brands: [""] },
      { name: "Embalagem", brands: [""] },
      { name: "Evelope", brands: [""] },
      { name: "Fichário", brands: [""] },
      { name: "Garrafa térmica", brands: [""] },
      { name: "Grafite", brands: [""] },
      { name: "Grampeador", brands: [""] },
      { name: "Grampo", brands: [""] },
      { name: "Guarda copo", brands: [""] },
      { name: "Guardanapo", brands: [""] },
      { name: "Guia de cabo", brands: ["Womer"] },
      { name: "Headset", brands: [""] },
      { name: "Inseticida", brands: [""] },
      { name: "Lacre", brands: [""] },
      { name: "Lanterna", brands: [""] },
      { name: "Lapiseira", brands: [""] },
      { name: "Livro Ata", brands: [""] },
      { name: "Lixeira", brands: [""] },
      { name: "Lápis", brands: [""] },
      { name: "Maleta", brands: [""] },
      { name: "Marca de texto", brands: [""] },
      { name: "Mochila", brands: [""] },
      { name: "Organizador", brands: [""] },
      { name: "Papel", brands: [""] },
      { name: "Papelão", brands: [""] },
      { name: "Pasta", brands: [""] },
      { name: "Perfurador", brands: [""] },
      { name: "Prancheta", brands: [""] },
      { name: "Prendedor", brands: [""] },
      { name: "Pulverizador", brands: [""] },
      { name: "Refil/Bebedouro", brands: [""] },
      { name: "Régua", brands: [""] },
      { name: "Sabonete", brands: [""] },
      { name: "Sabão", brands: [""] },
      { name: "Talheres", brands: [""] },
      { name: "Tinta impressora", brands: [""] },
      { name: "Toner impressora", brands: [""] },
    ],
  },
  {
    name: "Acessórios gerais",
    descriptions: [
      { name: "Cilindro", brands: [""] },
      { name: "Placas avisos", brands: [""] },
      { name: "Placas sinalizações", brands: [""] },
      { name: "Rolamento", brands: [""] },
    ],
  },
  {
    name: "Alarme",
    descriptions: [
      { name: "Alarme porta", brands: [""] },
      { name: "Alarme sonoro", brands: [""] },
      { name: "Alarme visual", brands: [""] },
      { name: "Cabo", brands: [""] },
    ],
  },
  {
    name: "Alimentos",
    descriptions: [
      { name: "Adoçante", brands: ["Linea","Stévia"] },
      { name: "Alimentação funcionário", brands: [""] },
      { name: "Açucar", brands: [""] },
      { name: "Café", brands: [""] },
      { name: "Chá", brands: [""] },
      { name: "Margarina", brands: [""] },
    ],
  },
  {
    name: "Aluguel de máquinas e equipamentos",
    descriptions: [
      { name: "Andaime", brands: [""] },
      { name: "Betoneira", brands: [""] },
      { name: "Betoneira", brands: [""] },
      { name: "Bomba", brands: [""] },
      { name: "Caminhão", brands: [""] },
      { name: "Caçamba", brands: [""] },
      { name: "Cilindro", brands: [""] },
      { name: "Compactador de solo", brands: [""] },
      { name: "Compressor", brands: [""] },
      { name: "Container", brands: [""] },
      { name: "Container", brands: [""] },
      { name: "Empilhadeira", brands: [""] },
      { name: "Equipamento movimentação vertical", brands: [""] },
      { name: "Martelete", brands: [""] },
      { name: "Martelo", brands: [""] },
      { name: "Mesa", brands: [""] },
      { name: "Mini escavadeira", brands: [""] },
      { name: "Mini geladeira", brands: [""] },
      { name: "Nobreak", brands: [""] },
      { name: "Painel", brands: [""] },
      { name: "Plataforma", brands: [""] },
      { name: "Plataforma", brands: [""] },
      { name: "Serra", brands: [""] },
      { name: "Vibrador", brands: [""] },
    ],
  },
  { name: "Bateria", descriptions: [{ name: "Silver Tape", brands: [""] }] },
  {
    name: "Bomba e Motores",
    descriptions: [
      { name: "Anel", brands: [""] },
      { name: "Bico", brands: [""] },
      { name: "Bomba", brands: [""] },
      { name: "Motor", brands: [""] },
      { name: "Rotor", brands: [""] },
    ],
  },
  { name: "Certificação", descriptions: [{ name: "Lixo zero", brands: [""] }] },
  {
    name: "Controlador",
    descriptions: [
      { name: "Local Bacnet", brands: [""] },
      { name: "Microcontrolador", brands: [""] },
      { name: "Para gerador", brands: [""] },
      { name: "Uso geral", brands: [""] },
      { name: "TCcontrol", brands: [""] },
      { name: "Wireless", brands: [""] },
    ],
  },
  {
    name: "Controle",
    descriptions: [
      { name: "Inteligente", brands: ["Intelbrás"] },
      { name: "Liga/Desliga", brands: [""] },
      { name: "Portão", brands: [""] },
      { name: "Remoto", brands: [""] },
    ],
  },
  {
    name: "Conversor",
    descriptions: [
      { name: "De TV digital", brands: [""] },
      { name: "De mídia", brands: ["Intelbrás"] },
    ],
  },
  {
    name: "EPI e EPC",
    descriptions: [
      { name: "Abafador auditivo", brands: [""] },
      { name: "Avental", brands: [""] },
      { name: "Balaclava", brands: [""] },
      { name: "Boné", brands: [""] },
      { name: "Bota de segurança", brands: [""] },
      { name: "Calça", brands: [""] },
      { name: "Camisa", brands: [""] },
      { name: "Capa", brands: [""] },
      { name: "Capacete", brands: [""] },
      { name: "Cinta ergonômica", brands: [""] },
      { name: "Cinto de segurança", brands: [""] },
      { name: "Colete", brands: [""] },
      { name: "Cone", brands: [""] },
      { name: "Jugular", brands: [""] },
      { name: "Luva", brands: [""] },
      { name: "Macacão", brands: [""] },
      { name: "Manga", brands: [""] },
      { name: "Máscara", brands: [""] },
      { name: "Protetor auricular", brands: [""] },
      { name: "Protetor solar", brands: [""] },
      { name: "Talabarte", brands: [""] },
      { name: "Tapete", brands: [""] },
      { name: "Touca", brands: [""] },
      { name: "Óculos", brands: [""] },
      { name: "Óculos de proteção", brands: [""] },
    ],
  },
  {
    name: "Elétrica e relacionados",
    descriptions: [
      { name: "Acoplamento", brands: ["Steck", "Mademil","Newkon"] },
      { name: "Adaptador", brands: ["Akasa"] },
      { name: "Anilha", brands: [""] },
      { name: "Aterramento", brands: [""] },
      { name: "Barramento", brands: [""] },
      { name: "Bobina", brands: [""] },
      { name: "Bocal", brands: [""] },
      { name: "Borne", brands: [""] },
      { name: "Box reto", brands: [""] },
      { name: "Cabeamento", brands: [""] },
      { name: "Caixa de aço", brands: [""] },
      { name: "Caixa de consolidação", brands: [""] },
      { name: "Caixa de embutir", brands: [""] },
      { name: "Caixa de inspeção", brands: [""] },
      { name: "Caixa de montagem", brands: [""] },
      { name: "Caixa de passagem", brands: [""] },
      { name: "Caixa de piso", brands: [""] },
      { name: "Calha", brands: [""] },
      { name: "Canaleta", brands: [""] },
      { name: "Capacitor", brands: [""] },
      { name: "Central de comando", brands: [""] },
      { name: "Circuito integrado", brands: [""] },
      { name: "Conduletes", brands: [""] },
      { name: "Conector", brands: [""] },
      { name: "Contator", brands: [""] },
      { name: "Copex", brands: [""] },
      { name: "Diodo", brands: [""] },
      { name: "Disjuntor", brands: [""] },
      { name: "Dissipador de calor", brands: [""] },
      { name: "Duto", brands: [""] },
      { name: "Eletrocalha", brands: [""] },
      { name: "Eletrodo", brands: [""] },
      { name: "Eletroduto", brands: [""] },
      { name: "Eletroimã", brands: [""] },
      { name: "Extensor", brands: [""] },
      { name: "Fio jumper", brands: [""] },
      { name: "Fusível", brands: [""] },
      { name: "Haste para aterramento", brands: [""] },
      { name: "Isolador", brands: [""] },
      { name: "Limpa contato", brands: [""] },
      { name: "Luva eletroduto roscável", brands: [""] },
      { name: "Mini disjuntor", brands: [""] },
      { name: "Path cord", brands: [""] },
      { name: "Petrolete", brands: [""] },
      { name: "Placa eletrônixa", brands: [""] },
      { name: "Plug", brands: [""] },
      { name: "Prensa cabos", brands: [""] },
      { name: "Protetor de surto", brands: [""] },
      { name: "Protetor térmico", brands: [""] },
      { name: "Quadro elétrico", brands: [""] },
      { name: "Relé", brands: [""] },
      { name: "Resistor", brands: [""] },
      { name: "Resistêcia", brands: [""] },
      { name: "Régua", brands: [""] },
      { name: "Terminais", brands: [""] },
      { name: "Tomada", brands: [""] },
      { name: "Transformador", brands: [""] },
      { name: "Unidut conector", brands: [""] },
      { name: "Unidut cônico", brands: [""] },
      { name: "Unidut múltiplo", brands: [""] },
      { name: "Unidut reto", brands: [""] },
    ],
  },
  {
    name: "Ferramentas, máquinas e utensílios para construção e reforma",
    descriptions: [
      { name: "Alavanca", brands: [""] },
      { name: "Alicate", brands: ["Gedore","Belzer","Tramontina", "Sata", "Furukawa"] },
      { name: "Aplicador", brands: [""] },
      { name: "Arco de serra", brands: [""] },
      { name: "Batedor de argamassa", brands: [""] },
      { name: "Broca", brands: [""] },
      { name: "Caixa", brands: [""] },
      { name: "Calço", brands: [""] },
      { name: "Chave", brands: [""] },
      { name: "Colher de pedreiro", brands: [""] },
      { name: "Compressora", brands: [""] },
      { name: "Cortador", brands: [""] },
      { name: "Cunha", brands: [""] },
      { name: "Desempenadeira", brands: [""] },
      { name: "Disco", brands: [""] },
      { name: "Enchada", brands: [""] },
      { name: "Escova", brands: [""] },
      { name: "Esmerilhadeira", brands: [""] },
      { name: "Espaçador", brands: [""] },
      { name: "Espátula", brands: [""] },
      { name: "Estilete", brands: ["Tramontina", "Stanley", "Vonder"] },
      { name: "Ferramenta de terminação", brands: [""] },
      { name: "Fio de solda", brands: [""] },
      { name: "Fluxo solda", brands: [""] },
      { name: "Fresa", brands: [""] },
      { name: "Furadeira", brands: [""] },
      { name: "Gancho", brands: [""] },
      { name: "Gás", brands: [""] },
      { name: "Lima", brands: [""] },
      { name: "Linha de pedreiro", brands: [""] },
      { name: "Lixa", brands: [""] },
      { name: "Lixadeira", brands: [""] },
      { name: "Lona", brands: [""] },
      { name: "Lâmina", brands: [""] },
      { name: "Mancal", brands: [""] },
      { name: "Mandril", brands: [""] },
      { name: "Mangote", brands: [""] },
      { name: "Mangueira", brands: [""] },
      { name: "Manta", brands: [""] },
      { name: "Marreta", brands: [""] },
      { name: "Martelete", brands: [""] },
      { name: "Martelo", brands: [""] },
      { name: "Maçarico", brands: [""] },
      { name: "Mexedor", brands: [""] },
      { name: "Mola", brands: [""] },
      { name: "Moto esmeril", brands: [""] },
      { name: "Parafina", brands: [""] },
      { name: "Parafusadeira", brands: [""] },
      { name: "Passa cabos", brands: [""] },
      { name: "Peneira", brands: [""] },
      { name: "Pistola", brands: [""] },
      { name: "Pistão", brands: [""] },
      { name: "Porta eletrodos", brands: [""] },
      { name: "Prumo", brands: [""] },
      { name: "Pá", brands: [""] },
      { name: "Pé de cabra", brands: [""] },
      { name: "Rebitador", brands: [""] },
      { name: "Retro escavadeira", brands: [""] },
      { name: "Roldanas", brands: [""] },
      { name: "Serra", brands: [""] },
      { name: "Serrote", brands: [""] },
      { name: "Solda", brands: [""] },
      { name: "Soquete", brands: [""] },
      { name: "Soquete", brands: [""] },
      { name: "Soquete", brands: [""] },
      { name: "Sugador de solda", brands: [""] },
      { name: "Suporte", brands: [""] },
      { name: "Talhadeira", brands: [""] },
      { name: "Tesoura", brands: [""] },
      { name: "Trena", brands: [""] },
      { name: "Trena", brands: [""] },
      { name: "Trilho", brands: [""] },
    ],
  },
  {
    name: "Filtro",
    descriptions: [
      { name: "Água", brands: [""] },
      { name: "Ar", brands: [""] },
      { name: "Coalescente", brands: [""] },
      { name: "Combustível", brands: [""] },
      { name: "Descartável", brands: [""] },
      { name: "Diesel", brands: [""] },
      { name: "Linha", brands: [""] },
      { name: "Manta", brands: [""] },      
      { name: "Óleo", brands: [""] },
    ],
  },
  {
    name: "Fitas",
    descriptions: [
      { name: "Adesiva", brands: [""] },
      { name: "Aluminizada", brands: [""] },
      { name: "Aluminizada", brands: [""] },
      { name: "Antiderrapante", brands: [""] },
      { name: "Borda", brands: [""] },
      { name: "Crepe", brands: [""] },
      { name: "Dupla face", brands: [""] },
      { name: "Durex", brands: [""] },
      { name: "Etiquetadora", brands: [""] },
      { name: "Isolate", brands: [""] },
      { name: "PVC", brands: [""] },
      { name: "Silver Tape", brands: [""] },
      { name: "Veda Rosca", brands: [""] },
      { name: "Vedação", brands: [""] },
      { name: "Velcro", brands: [""] },
      { name: "Walsuwa", brands: [""] },
      { name: "Zebrada", brands: [""] },
    ],
  },
  {
    name: "Fixação",
    descriptions: [
      { name: "Abraçadeira", brands: [""] },
      { name: "Arrebite", brands: [""] },
      { name: "Arruela", brands: [""] },
      { name: "Bits", brands: [""] },
      { name: "Bucha", brands: [""] },
      { name: "Chumbador", brands: [""] },
      { name: "Cola", brands: [""] },
      { name: "Conector", brands: [""] },
      { name: "Parabolt", brands: [""] },
      { name: "Parafuso", brands: [""] },
      { name: "Pino", brands: [""] },
      { name: "Porca", brands: [""] },
      { name: "Prego", brands: [""] },
      { name: "Rebite", brands: [""] },
      { name: "Silicone", brands: [""] },
    ],
  },
  {
    name: "Iluminação",
    descriptions: [
      { name: "Arandela", brands: [""] },
      { name: "Caixa de luz", brands: [""] },
      { name: "Interruptor", brands: [""] },
      { name: "Luminária", brands: [""] },
      { name: "Lâmpada", brands: [""] },
      { name: "Refletor", brands: [""] },
    ],
  },
  {
    name: "Incêndio",
    descriptions: [
      { name: "Acionador endereçável", brands: ["Apollo","Intelbrás"] },
      { name: "Acionador manual", brands: [""] },
      { name: "Alisar para porta", brands: [""] },
      { name: "Arquivo morto", brands: [""] },
      { name: "Central de alarme", brands: [""] },
      { name: "Detector de fumaça", brands: [""] },
      { name: "Hidrante", brands: [""] },
      { name: "Manual", brands: ["Apollo","Intelbrás"] },
    ],
  },
  {
    name: "Informática, rede e telefonia",
    descriptions: [
      { name: "Adaptador", brands: [""] },
      { name: "Antena", brands: [""] },
      { name: "Belt de transferência", brands: [""] },
      { name: "Cabo", brands: [""] },
      { name: "Caixa hermética", brands: [""] },
      { name: "Cartão de memória", brands: [""] },
      { name: "Case Raspberry", brands: [""] },
      { name: "Chip", brands: [""] },
      { name: "Computador", brands: [""] },
      { name: "Cooler", brands: [""] },
      { name: "Cordoalha", brands: [""] },
      { name: "Cordão óptico", brands: [""] },
      { name: "Dio", brands: [""] },
      { name: "Display", brands: [""] },
      { name: "Fibra óptica", brands: [""] },
      { name: "Gabinete", brands: [""] },
      { name: "Memória", brands: [""] },
      { name: "Mesa digitalizadora", brands: [""] },
      { name: "Modem", brands: [""] },
      { name: "Monitor", brands: [""] },
      { name: "Montagem e configuração", brands: [""] },
      { name: "Mouse", brands: [""] },
      { name: "Módulo", brands: [""] },
      { name: "Nobreak", brands: [""] },
      { name: "Pen drive", brands: [""] },
      { name: "Placa de vídeo", brands: [""] },
      { name: "Placa mãe", brands: [""] },
      { name: "Processador", brands: [""] },
      { name: "Projetor de tela", brands: [""] },
      { name: "Raspeberry", brands: [""] },
      { name: "Roteador", brands: [""] },
      { name: "Switch", brands: [""] },
      { name: "Tablet", brands: [""] },
      { name: "Webcam", brands: [""] },
    ],
  },
  {
    name: "Itens para manutenção",
    descriptions: [
      { name: "Aditivo", brands: [""] },
      { name: "Anticorrosivo", brands: [""] },
      { name: "Bactericida", brands: [""] },
      { name: "Cloro", brands: [""] },
      { name: "Câmera de ar", brands: [""] },
      { name: "Desengraxante", brands: [""] },
      { name: "Desengripante", brands: ["WD-40"] },
      { name: "Lubricante", brands: [""] },
      { name: "Recargas", brands: [""] },
      { name: "Óleo", brands: [""] },
    ],
  },
  {
    name: "Materiais e produtos de limpeza",
    descriptions: [
      { name: "Algicida", brands: [""] },
      { name: "Balde", brands: [""] },
      { name: "Bucha", brands: [""] },
      { name: "Cabo", brands: [""] },
      { name: "Cera", brands: [""] },
      { name: "Desentupidor", brands: [""] },
      { name: "Desinfetante", brands: [""] },
      { name: "Desorizante", brands: [""] },
      { name: "Detergente", brands: [""] },
      { name: "Espanador", brands: [""] },
      { name: "Fibra", brands: [""] },
      { name: "Flanela", brands: [""] },
      { name: "Limpa Pisos", brands: ["Azulim", "UAU"] },
      { name: "Limpa pedra", brands: ["Pedrex"] },
      { name: "Limpador Multiuso", brands: [""] },
      { name: "Limpesa pesada", brands: ["Veja", "Ype"] },
      { name: "Panos", brands: [""] },
      { name: "Peneira", brands: [""] },
      { name: "Prolongador", brands: ["Atlas"] },
      { name: "Pá", brands: [""] },
      { name: "Rastelo", brands: [""] },
      { name: "Rodo", brands: [""] },
      { name: "Saco de lixo", brands: [""] },
      { name: "Saco para aspirador", brands: [""] },
      { name: "Solução ácida", brands: [""] },
      { name: "Suporte", brands: [""] },
      { name: "Vassoura", brands: [""] },
      { name: "Água Sanitária", brands: ["QBOA"] },
      { name: "Álcool", brands: [""] },
    ],
  },
  {
    name: "Materiais, ferragens e marcenaria para construção e reformas",
    descriptions: [
      { name: "Aditivo", brands: ["Prodopas"] },
      { name: "Alizar", brands: [""] },
      { name: "Alumínio corrugado", brands: [""] },
      { name: "Alçapão", brands: [""] },
      { name: "Ancoragem", brands: [""] },
      { name: "Arame", brands: [""] },
      { name: "Areia", brands: [""] },
      { name: "Argamassa", brands: ["VOTORANTIM", "QUARTZOLIT"] },
      { name: "Argila", brands: [""] },
      { name: "Asfalto", brands: [""] },
      { name: "Baguete reto", brands: [""] },
      { name: "Bandeja", brands: [""] },
      { name: "Barra", brands: [""] },
      { name: "Barra de ferro", brands: [""] },
      { name: "Barrila", brands: [""] },
      { name: "Bloco de concreto", brands: [""] },
      { name: "Bloco de contato", brands: [""] },
      { name: "Bloco de espuma", brands: [""] },
      { name: "Brita", brands: [""] },
      { name: "Caixa de gordura", brands: [""] },
      { name: "Calfix", brands: [""] },
      { name: "Cantoneira", brands: [""] },
      { name: "Carrinho", brands: [""] },
      { name: "Cascalho", brands: [""] },
      { name: "Chapa", brands: [""] },
      { name: "Cimento", brands: [""] },
      { name: "Coluna", brands: [""] },
      { name: "Compensado", brands: [""] },
      { name: "Concertina", brands: [""] },
      { name: "Concreto", brands: [""] },
      { name: "Corda", brands: [""] },
      { name: "Correias", brands: [""] },
      { name: "Corrente", brands: [""] },
      { name: "Dobradiça", brands: [""] },
      { name: "Durepox", brands: [""] },
      { name: "Escada", brands: [""] },
      { name: "Espuma", brands: [""] },
      { name: "Esquadrias", brands: [""] },
      { name: "Esquadro", brands: [""] },
      { name: "Estrutura de alumínio", brands: [""] },
      { name: "Estrutura metálica", brands: [""] },
      { name: "Exaustor", brands: [""] },
      { name: "Fechadura", brands: [""] },
      { name: "Fecho", brands: [""] },
      { name: "Forro", brands: [""] },
      { name: "Gaxetas", brands: [""] },
      { name: "Gesso", brands: [""] },
      { name: "Grade", brands: [""] },
      { name: "Gradil", brands: [""] },
      { name: "Granitina", brands: [""] },
      { name: "Graxa", brands: [""] },
      { name: "Grelha", brands: [""] },
      {
        name: "Impermeabilizante",
        brands: ["Prodopas", "Rhodopas", "Vedalit", "Vedatop", "Sika"],
      },
      { name: "Junta", brands: [""] },
      { name: "Laje", brands: [""] },
      { name: "Leito", brands: [""] },
      { name: "Lençol", brands: [""] },
      { name: "Lima", brands: [""] },
      { name: "Lã de rocha", brands: [""] },
      { name: "MDF", brands: [""] },
      { name: "Madeira", brands: [""] },
      { name: "Madeirite", brands: [""] },
      { name: "Malha", brands: [""] },
      { name: "Massa adesiva", brands: [""] },
      { name: "Massa corrida", brands: [""] },
      {
        name: "Massa corrida",
        brands: ["Coral", "Metalex", "Kokar", "Suvinil"],
      },
      { name: "Massa plástica", brands: [""] },
      { name: "Massa pronta", brands: [""] },
      { name: "Masseira", brands: [""] },
      { name: "Metalon", brands: [""] },
      { name: "Mármore e granito", brands: [""] },
      { name: "Pasta", brands: [""] },
      { name: "Pedra", brands: [""] },
      { name: "Perfil", brands: [""] },
      { name: "Perfilado", brands: [""] },
      { name: "Piso", brands: [""] },
      { name: "Placa", brands: [""] },
      { name: "Policarbonato", brands: [""] },
      { name: "Polipex", brands: [""] },
      { name: "Porcelanato", brands: [""] },
      { name: "Porta", brands: [""] },
      { name: "Pressostato", brands: [""] },
      { name: "Regulador", brands: [""] },
      { name: "Rejunte", brands: [""] },
      { name: "Resina", brands: [""] },
      { name: "Revestimento", brands: [""] },
      { name: "Régua", brands: [""] },
      { name: "Solenoide", brands: [""] },
      { name: "Sulfato de alumínio", brands: [""] },
      { name: "Tela", brands: [""] },
      { name: "Telha", brands: [""] },
      { name: "Terminal de compressão", brands: [""] },
      { name: "Tijolo", brands: [""] },
      { name: "Torneira", brands: [""] },
      { name: "Veda calha", brands: [""] },
      { name: "Ventosa", brands: [""] },
      { name: "Vergalhão", brands: [""] },
      { name: "Vidro", brands: [""] },
    ],
  },
  {
    name: "Medidores",
    descriptions: [
      { name: "Amperímetro", brands: [""] },
      { name: "Analisador", brands: [""] },
      { name: "Anemômetro", brands: [""] },
      { name: "Balança", brands: [""] },
      { name: "Bóia de nível", brands: [""] },
      { name: "Hidrômetro", brands: [""] },
      { name: "Leitor", brands: [""] },
      { name: "Localizador de cabo", brands: [""] },
      { name: "Manômetro", brands: [""] },
      { name: "Medidor de energia", brands: [""] },
      { name: "Medidor de água", brands: [""] },
      { name: "Multimedidor", brands: [""] },
      { name: "Multimetro", brands: [""] },
      { name: "Nível de mão", brands: [""] },
      { name: "Termostato", brands: [""] },
      { name: "Termômetro", brands: [""] },
      { name: "Transmissor de nível hidrostático", brands: [""] },
    ],
  },
  {
    name: "Móveis, acessórios e eletrodomésticos",
    descriptions: [
      { name: "Armário", brands: [""] },
      { name: "Aspirador", brands: [""] },
      { name: "Bancada", brands: [""] },
      { name: "Bandeja", brands: [""] },
      { name: "Cadeira", brands: [""] },
      { name: "Claviculário", brands: [""] },
      { name: "Cuba", brands: [""] },
      { name: "Estante", brands: [""] },
      { name: "Gaveteiro", brands: [""] },
      { name: "Impressora", brands: [""] },
      { name: "Mesa", brands: [""] },
      { name: "Microondas", brands: [""] },
      { name: "Nincho", brands: [""] },
      { name: "Plataforma de trabalho individual", brands: [""] },
      { name: "Poltrona", brands: [""] },
      { name: "Prateleira", brands: [""] },
      { name: "Puff", brands: [""] },
      { name: "Puxador", brands: [""] },
      { name: "Quadro branco", brands: [""] },
      { name: "Rack", brands: [""] },
      { name: "Rodízio fel", brands: [""] },
      { name: "Ropeiro", brands: [""] },
      { name: "Suporte", brands: [""] },
      { name: "Televisão", brands: [""] },
      { name: "Totem", brands: [""] },
      { name: "Umidificador", brands: [""] },
      { name: "Ventilador", brands: [""] },
    ],
  },
  {
    name: "Produtos para dar carga",
    descriptions: [
      { name: "Bateria", brands: [""] },
      { name: "Carregador", brands: [""] },
      { name: "Fonte", brands: [""] },
      { name: "Pilha", brands: [""] },
    ],
  },
  {
    name: "Registros",
    descriptions: [
      { name: "Base", brands: [""] },
      { name: "De gaveta", brands: [""] },
      { name: "De pressão", brands: [""] },
      { name: "Esfera", brands: [""] },
    ],
  },
  {
    name: "Reservatório",
    descriptions: [
      { name: "Caixa de gordura", brands: [""] },
      { name: "Caixa de água", brands: ["Apollo"] },
      { name: "Tanque", brands: [""] },
    ],
  },
  {
    name: "Sanitários",
    descriptions: [
      { name: "Assento", brands: [""] },
      { name: "Bacia", brands: [""] },
      { name: "Barra de apoio", brands: [""] },
      { name: "Bico", brands: [""] },
      { name: "Caixa acoplada", brands: [""] },
      { name: "Caixa de descarga", brands: [""] },
      { name: "Chuveiro", brands: [""] },
      { name: "Descarga", brands: ["Docol", "Hydra"] },
      { name: "Difusor", brands: [""] },
      { name: "Haste para chuveiro", brands: [""] },
      { name: "Lavatório", brands: [""] },
      { name: "Mictório", brands: [""] },
      { name: "Obturadores", brands: [""] },
      { name: "Pastilha", brands: [""] },
      { name: "Vaso sanitário", brands: [""] },
    ],
  },
  {
    name: "Segurança",
    descriptions: [
      { name: "ACD", brands: ["Bosch"] },
      { name: "Acionador de emergência", brands: ["Intelbrás"] },
      { name: "Acionador de saída", brands: [""] },
      { name: "Camera", brands: ["Bosch"] },
      { name: "Cancela", brands: [""] },
      { name: "Catraca", brands: ["Bosch"] },
      { name: "Controle de acesso", brands: ["Bosch"] },
      { name: "Licença", brands: ["Bosch"] },
      { name: "Teclado", brands: ["Bosch"] },
      { name: "Receptor", brands: ["Bosch"] },
      { name: "Porta", brands: ["Bosch"] },
      { name: "Placa de montagem", brands: ["Bosch"] },
      { name: "Transmissor universal", brands: ["Bosch"] },
      { name: "Chave USB", brands: ["Bosch"] },
    ],
  },
  {
    name: "Sensor",
    descriptions: [
      { name: "Infravermelho", brands: [""] },
      { name: "Magnético", brands: [""] },
      { name: "Presença", brands: [""] },
      { name: "Pressão", brands: [""] },
      { name: "Proximidade", brands: [""] },
      { name: "Temperatura", brands: [""] },
      { name: "Vazão", brands: [""] },
      { name: "Vento", brands: [""] },
    ],
  },
  {
    name: "Serviços",
    descriptions: [
      { name: "Ar externo", brands: [""] },
      { name: "Assentamento", brands: [""] },
      { name: "Calibração", brands: [""] },
      { name: "Chapeamento", brands: [""] },
      { name: "Confecção", brands: [""] },
      { name: "Conserto", brands: [""] },
      { name: "Cursos e trei'name'nto", brands: [""] },
      { name: "Descarte", brands: [""] },
      { name: "Fornecimento", brands: [""] },
      { name: "Frete", brands: [""] },
      { name: "Gráfica", brands: [""] },
      { name: "Hora extra", brands: [""] },
      { name: "Impermeabilização", brands: [""] },
      { name: "Instalação", brands: [""] },
      { name: "Limpeza", brands: [""] },
      { name: "Lixamento", brands: [""] },
      { name: "Manutenção", brands: [""] },
      { name: "Montagem", brands: [""] },
      { name: "Pintura", brands: [""] },
      { name: "Pisos", brands: [""] },
      { name: "Reforma", brands: [""] },
      { name: "Suporte técnico", brands: [""] },
      { name: "Teste e inspeção", brands: [""] },
      { name: "Troca", brands: [""] },
    ],
  },
  {
    name: "Sistema de refrigeração",
    descriptions: [
      { name: "Ar condicionado", brands: [""] },
      { name: "Atenuador", brands: [""] },
      { name: "Base serra copo", brands: [""] },
      { name: "Colarinho", brands: [""] },
      { name: "Compressor", brands: [""] },
      { name: "Defletor", brands: [""] },
      { name: "Duto", brands: [""] },
      { name: "Hélice", brands: [""] },
      { name: "Isolamento térmico", brands: ["Armaflex"] },
      { name: "Unidade condensadora", brands: [""] },
      { name: "Unidade evaporadora", brands: [""] },
    ],
  },
  {
    name: "Software",
    descriptions: [
      { name: "Bosch", brands: [""] },
      { name: "MBV", brands: [""] },
      { name: "Mac", brands: [""] },
      { name: "Milestone", brands: [""] },
      { name: "Windows server", brands: [""] },
    ],
  },
  {
    name: "Tubulação",
    descriptions: [
      { name: "Adaptador", brands: [""] },
      { name: "Adesivo", brands: ["Amanco", "Tigre"] },
      { name: "Anel", brands: [""] },
      { name: "Arejador", brands: [""] },
      { name: "Bucha de redução para esgoto", brands: ["Tigre"] },
      { name: "Cabeçote hidráulico", brands: [""] },
      { name: "Caixa sinfonada", brands: [""] },
      { name: "Cap Normal para esgoto", brands: [""] },
      { name: "Conexão galvanizada", brands: [""] },
      { name: "Conexões para Água Fria", brands: [""] },
      { name: "Cotovelo galvanizado", brands: [""] },
      { name: "Curva galvanizada", brands: [""] },
      { name: "Curva para esgoto", brands: [""] },
      { name: "Curva soldável para água", brands: [""] },
      { name: "Curvador de tubos", brands: [""] },
      { name: "Curvo de cobre", brands: [""] },
      { name: "Engate flexível", brands: [""] },
      { name: "Flange", brands: [""] },
      { name: "Joelho para esgoto", brands: [""] },
      { name: "Joelho soldável", brands: [""] },
      { name: "Junção Simples para esgoto", brands: [""] },
      { name: "Junção para esgoto", brands: [""] },
      { name: "Luva PVC soldável", brands: [""] },
      { name: "Luva de emenda", brands: [""] },
      { name: "Luva galvanizada", brands: [""] },
      { name: "Luva para esgoto", brands: [""] },
      { name: "Niple", brands: [""] },
      { name: "Prolongador", brands: [""] },
      { name: "Redução para esgoto", brands: [""] },
      { name: "Sifão", brands: [""] },
      { name: "Soldáveis", brands: [""] },
      { name: "TEE PVC para esgoto", brands: [""] },
      { name: "TEE PVC soldável", brands: [""] },
      { name: "TEE galvanizado", brands: [""] },
      { name: "Tubo PVC esgoto", brands: [""] },
      { name: "Tubo PVC soldável", brands: [""] },
      { name: "Tubo de cobre", brands: [""] },
      { name: "Tubo galvanizado", brands: [""] },
      { name: "Tubo industrial", brands: [""] },
      { name: "Tubo ligação", brands: [""] },
      { name: "TÚE PVC para esgoto", brands: [""] },
      { name: "União PVC soldável", brands: [""] },
      { name: "União de cobre", brands: [""] },
      { name: "União galvanizado", brands: [""] },
      { name: "Vedação para esgoto", brands: [""] },
    ],
  },
  {
    name: "Uniforme",
    descriptions: [
      { name: "Blazer", brands: [""] },
      { name: "Blusa", brands: [""] },
      { name: "Calça", brands: [""] },
      { name: "Camisa", brands: [""] },
      { name: "Camiseta", brands: [""] },
      { name: "Colete", brands: [""] },
      { name: "Jalece", brands: [""] },
      { name: "Jaqueta", brands: [""] },
      { name: "Meia", brands: [""] },
      { name: "Vestido", brands: [""] },
    ],
  },
  {
    name: "Válvulas",
    descriptions: [
      { name: "Acabamento", brands: ["Docol"] },
      { name: "Americana", brands: [""] },
      { name: "Atuador", brands: [""] },
      { name: "Borboleta", brands: [""] },
      { name: "Eferas", brands: [""] },
      { name: "Escolamento", brands: [""] },
      { name: "Registro", brands: [""] },
      { name: "Retenção", brands: [""] },
      { name: "Solda", brands: [""] },
    ],
  },
  {
    name: "Vídeo e audio",
    descriptions: [
      { name: "Axis", brands: [""] },
      { name: "Equipamentos", brands: [""] },
      { name: "Indicador de audio visual", brands: [""] },
    ],
  },
];

export default departments;
