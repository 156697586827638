import React, { useState } from 'react';
import  "./InfoButton.css";


function InfoButtonUnit() {
  const [showText, setShowText] = useState(false);

  function handleClick() {
    setShowText(!showText);
  }

  return (
    <div className="info-button-container">
      <button className="info-button" onClick={handleClick}>
        !
      </button>
      {showText && <div className="info-text"> Coloque APENAS o que aparece no nome do insumo. Caso não haja nenhuma informação de tamanho ou forma de produto, coloque Quantidade - Unidades - 1 (Quer dizer que tem uma unidade do produto) </div>}
    </div>
  );
}

export default InfoButtonUnit;