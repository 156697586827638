import React from "react";
import departments from "./departments";

function ShowModels(props) {
  const { expandedItem,toggleExpansion } = props;

  return (
    <div
      style={{
        columnCount: 4,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        margin: "20px",
        fontFamily: "Arial, sans-serif",
        fontSize: "10%",
        padding: "5vh",

      }}
    >
      <ul style={{ marginTop: "0px", marginBottom: "0px" }}>
        {departments.map((department, index) => (
          <li key={index}>
            <div
              style={{ marginTop: "5px", fontSize: "1vw" }}
              onClick={() => toggleExpansion(index)}
            >
              {department.name} {expandedItem === index ? "▼" : "►"}
            </div>
            {expandedItem === index && (
              <ul style={{ marginTop: "5px", fontSize: "0.8vw" }}>
                {department.descriptions.map((description, i) => (
                  <li key={i}>{description.name}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      
    </div>
  );
}

export default ShowModels;
