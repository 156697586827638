import React, { useState } from "react";
import "./App.css";

const ExampleButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  function toggleExpansionModels() {
    setModalOpen(!modalOpen);
  } 
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button className="button-style" onClick={toggleExpansionModels}>
          Exemplo de Classificação
        </button>
      </div>
      {modalOpen && (
        <div
          className="modal"
          style={{
            columnCount: 2,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            margin: "20px",
            fontFamily: "Arial, sans-serif",
            fontSize: "100%",
            padding: "20px",
          }}
        >
          <h2>Informações do Produto</h2>
          <p>ABRAÇADEIRA DE NYLON 3,6MM X 14CM BRANCA</p>
          <p><h3>Departamento:</h3> Abraçadeira</p>
          <p><h3>Descrição:</h3> Nylon</p>
          <p><h3>Tipo de unidade:</h3> Largura x comprimento</p>
          <p><h3>Unidade:</h3> mm x cm</p>
          <p><h3>Valor:</h3> 3.6x14</p>
          
        </div>
      )}
    </div>
  );
};

export default ExampleButton;
