const characteristics = [
  {
    name: "Capacidade",
    unit: [{ name: "Litros" }, { name: "Mililitros" }, {name: 'Kilogramas'}],
  },
  {
    name: "Folhas",
    unit: [
      { name: "Número" },
    ],
  },
  {
    name: "Expessura",
    unit: [
      { name: "mm²" },
    ],
  },
  {
    name: "Calçados",
    unit: [
      { name: "Numeração" },
    ],
  },
  {
    name: "Comprimento",
    unit: [
      { name: "Centímetros" },
      { name: "Metros" },
      { name: "Milímetros" },
    ],
  },
  {
    name: "Largura",
    unit: [
      { name: "Centímetros" },
      { name: "Metros" },
      { name: "Milímetros" },
    ],
  },  
  {
    name: "Largura X Comprimento",
    unit: [
      { name: "cm x mm" },
      { name: "cm x cm" },
      { name: "cm x m" },
      { name: "mm x mm" },
      { name: "mm x cm" },
      { name: "mm x m" },
      { name: "m x mm" },
      { name: "m x cm" },
      { name: "m x m" },
    ],
  },
  {
    name: "Comprimento X Diâmetro",
    unit: [
      { name: "mm x pol" },
    ],
  },
  {
    name: "Diâmetro",
    unit: [
      { name: "Centímetros" },
      { name: "Metros" },
      { name: "Milímetros" },
      { name: "Polegadas" },
    ],
  },
  { name: "Quantidade", unit: [{ name: "Unidades" }, { name: "Caixas" }] },
  
  { name: "Peso", unit: [{ name: "Grama" }, { name: "Quilograma" }] },
];

export default characteristics;
