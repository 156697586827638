import React, { useState, useEffect } from "react";
import "./App.css";
import Modal from "react-modal";
import departments from "./departments";
import characteristics from "./characteristics";
import people from "./names";

import ShowModels from "./ShowModels";
import ShowSelect from "./ShowSelect";
import ShowInput from "./ShowInput";
import ExampleButton from "./ExampleButton";
import InfoForClassification from "./InfoForClassification";
import FlowChart from "./FlowChart";
import InfoButtonCharac from "./InfoButtonCharac";
import InfoButtonDimen from "./InfoButtonDimen";
import InfoButtonUnit from "./InfoButtonUnit";

export default function App() {
  const [selectedName, setSelectedName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedCharacteristic, setSelectedCharacteristic] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedUnitType, setSelectedUnitType] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedNumber, setSelectedNumber] = useState("");
  const [selectedWidthNumber, setSelectedWidthNumber] = useState("");
  const [selectedLenghtNumber, setSelectedLenghtNumber] = useState("");
  const [selectedDiamNumber, setSelectedDiamNumber] = useState("");

  const [selectedCountNumber, setCountNumber] = useState(0);

  const [showCharacteristics, setShowCharacteristics] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [showBrandForm, setShowBrandForm] = useState(false);
  const [newBrand, setNewBrand] = useState("");
  const [verified, setVerify] = useState("verified");
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedItem, setExpandedItem] = useState(-1);
  const [data, setData] = useState(null);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonAddDisabled, setButtonAddDisabled] = useState(true);

  useEffect(() => {
    (async function () {
      const { text } = await (await fetch(`/api/GetInfoAzureTable`)).json();
      setData(text);
    })();
  });

  function toggleExpansionModels() {
    setIsExpanded(!isExpanded);
  }

  const toggleExpansion = (index) => {
    if (expandedItem === index) {
      setExpandedItem(-1);
    } else {
      setExpandedItem(index);
    }
  };

  const handleNameChange = (event) => {
    setSelectedName(event.target.value);
    if ((selectedDescription !== "") & (selectedDepartment !== "")) {
      setButtonDisabled(false);
    }
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
    setSelectedDescription("");
    setSelectedCharacteristic("");
    setSelectedBrand("");
  };

  const handleDescriptionChange = (event) => {
    setSelectedDescription(event.target.value);

    let department = selectedDepartment.toLowerCase();
    let descrip = event.target.value.toLowerCase();
    value = data.toLowerCase();
    let charac = value.replaceAll(department, "");
    charac = charac.replaceAll(descrip, "");

    setSelectedCharacteristic(charac);
    setSelectedBrand("");

    if (selectedName !== "") {
      setButtonDisabled(false);
    }
  };

  const handleCharacteristicChange = (event) => {
    setSelectedCharacteristic(event.target.value);
    setSelectedBrand("");
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  const handleUnitTypeChange = (event) => {
    setSelectedUnitType(event.target.value);
    setSelectedUnit("");
  };

  const handleUnitChange = (event) => {
    setSelectedUnit(event.target.value);
  };

  const handleButtonClick2 = () => {
    setShowCharacteristics(!showCharacteristics);
  };

  const handleTypeClick = (type) => {
    if (selectedType === type) {
      setSelectedType(-1);
    } else {
      setSelectedType(type);
    }
  };

  const getDescriptions = () => {
    const selectedDepartmentObject = departments.find(
      (department) => department.name === selectedDepartment
    );
    return selectedDepartmentObject
      ? selectedDepartmentObject.descriptions
      : [];
  };

  const getUnits = () => {
    const selectedUnitTypeObject = characteristics.find(
      (UnitType) => UnitType.name === selectedUnitType
    );
    return selectedUnitTypeObject ? selectedUnitTypeObject.unit : [];
  };

  const getBrands = () => {
    const selectedDescriptionObject = getDescriptions().find(
      (description) => description.name === selectedDescription
    );
    let brands = selectedDescriptionObject
      ? selectedDescriptionObject.brands
      : [];

    // add new brand to list if not already included
    if (newBrand && !brands.includes(newBrand)) {
      brands = [...brands, newBrand];
    }

    return brands;
  };

  const brands = getBrands();

  const handleButtonClick = () => {
    setShowInputs(true);
    setButtonAddDisabled(false);
    setButtonDisabled(true);
    setVerify("new");
    restartInfo();
  };

  function restartInfo() {
    setSelectedDepartment("");
    setSelectedDescription("");
    setSelectedCharacteristic("");
    setSelectedBrand("");
    setSelectedColor("");
    setSelectedModel("");
    setSelectedNumber("");
    setSelectedLenghtNumber("");
    setSelectedWidthNumber("");
    setSelectedType("");
    setSelectedUnit("");
    setSelectedUnitType("");
    setButtonDisabled(true);
  }
  const handleCancelButtonClick = () => {
    restartInfo();
    setShowInputs(false);
    setButtonAddDisabled(true);
    setVerify("verified");
  };

  const handleAddBrandClick = () => {
    setShowBrandForm(true);
  };

  const handleNewBrandChange = (event) => {
    setNewBrand(event.target.value);
  };

  const handleSaveBrandClick = () => {
    setSelectedBrand(newBrand);
    setShowBrandForm(false);
  };

  const handleSubmit = () => {
    setCountNumber((selectedCountNumber + 1));
    const supply = {
      insumo: data,
      departamento: selectedDepartment,
      nome: selectedName,
      descricao: selectedDescription,
      caracteristicas: selectedCharacteristic,
      marca: selectedBrand,
      status_info: verified,
      modelo: selectedModel,
      cor: selectedColor,
      comprimento: selectedLenghtNumber,
      largura: selectedWidthNumber,
      diametro: selectedDiamNumber,
      tipoDeUnidade: selectedUnitType,
      unidade: selectedUnit,
      valor: selectedNumber,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(supply),
    };

    fetch("/api/GetInfoAzureTable", options)
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        setModalIsOpen(true);
        restartInfo();
        setVerify("verified");
        setShowInputs(false);
        setButtonAddDisabled(true);
      })
      .catch((error) => console.error(error));
  };

  let value = "Ar condicionado baçba";

  return (
    <div>
      <div className="centered-container">
        <InfoForClassification />
        <ExampleButton />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={toggleExpansionModels} className="button-style">
            Modelos
          </button>
        </div>
        {isExpanded && (
          <ShowModels
            expandedItem={expandedItem}
            toggleExpansion={toggleExpansion}
          />
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={handleButtonClick2} className="button-style">
            Especificações
          </button>
        </div>
        {showCharacteristics && (
          <div
            style={{
              columnCount: 1,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              fontFamily: "Arial, sans-serif",
              fontSize: "100%",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              width: "15vw",
              margin: "2vh auto",
            }}
          >
            <div key={characteristics.name}>
              <ul style={{ marginTop: "0px", marginBottom: "0px" }}>
                {characteristics.map((type) => (
                  <li key={type.name} onClick={() => handleTypeClick(type)}>
                    {type.name} {selectedType === type ? "▼" : "►"}
                    {selectedType === type && (
                      <ul>
                        {type.unit.map((unit) => (
                          <li key={unit.name}>{unit.name}</li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <h3
          style={{
            textAlign: "center",
            fontSize: "2em",
            marginTop: "5vh",
            marginBottom: "-1vh",
          }}
        >
          Fluxograma de classificação
        </h3>
        <FlowChart />
        <h2
          style={{
            textAlign: "center",
            fontSize: "2em",
            marginTop: "5vh",
            marginBottom: "-1vh",
          }}
        >
          Número de insumos classificados: {selectedCountNumber}
        </h2>
        <div className="line"></div>
        {data ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "2.5em",
              marginRight: "1vh",
            }}
          >
            <p>Insumo a ser classificado: </p>
            <p>{data}</p>
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              fontSize: "2.5em",
              marginRight: "1vh",
            }}
          >
            <p>Carregando...</p>
          </div>
        )}
        <>
          <label htmlFor="name-selector" className="form-label">
            Selecione o seu nome:
          </label>
          <select
            id="name-selector"
            onChange={handleNameChange}
            value={selectedName}
            className="select-box"
          >
            <option value=""></option>
            {people.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        </>
        {!showInputs && (
          <>
            <label htmlFor="department-selector" className="form-label">
              Selecione o departamento:
            </label>
            <ShowSelect
              handleDepartmentChange={handleDepartmentChange}
              selectedDepartment={selectedDepartment}
              selectBoxId="department-selector"
              selectBoxClassName="select-box"
              selectBoxOptions={departments}
            />
          </>
        )}

        {!showInputs && selectedDepartment && (
          <div className="centered-container">
            <label htmlFor="description-selector" className="form-label">
              Selecione a descrição:
            </label>
            <ShowSelect
              handleDepartmentChange={handleDescriptionChange}
              selectedDepartment={selectedDescription}
              selectBoxId="description-selector"
              selectBoxClassName="select-box"
              selectBoxOptions={getDescriptions()}
            />
          </div>
        )}
        {!showInputs && (
          <div className="centered-container">
            <label htmlFor="characteristic-selector" className="form-label">
              Insira a característica: <InfoButtonCharac />
            </label>

            <input
              type="text"
              id="characteristic-input"
              value={selectedCharacteristic}
              onChange={(event) =>
                setSelectedCharacteristic(event.target.value)
              }
              className="input-box"
            />
          </div>
        )}
        {!showInputs && (
          <div className="centered-container">
            <label htmlFor="type-selector" className="form-label">
              Selecione o tipo de unidade: <InfoButtonUnit />
            </label>
            <ShowSelect
              handleDepartmentChange={handleUnitTypeChange}
              selectedDepartment={selectedUnitType}
              selectBoxId="type-selector"
              selectBoxClassName="select-box"
              selectBoxOptions={characteristics}
            />
          </div>
        )}

        {!showInputs && selectedUnitType && (
          <div className="centered-container">
            <label htmlFor="type-selector" className="form-label">
              Selecione a métrica:
            </label>
            <ShowSelect
              handleDepartmentChange={handleUnitChange}
              selectedDepartment={selectedUnit}
              selectBoxId="type-selector"
              selectBoxClassName="select-box"
              selectBoxOptions={getUnits()}
            />
          </div>
        )}

        {!showInputs && selectedUnit && (
          <div className="centered-container">
            <label htmlFor="number-selector" className="form-label">
              Insira o valor: <InfoButtonDimen />
            </label>
            {selectedUnitType === "Largura X Comprimento" && (
              <div style={{ display: "flex", margin: "0 auto" }}>
                <input
                  type="number"
                  id="number-width-input"
                  value={selectedWidthNumber}
                  onChange={(event) =>
                    setSelectedWidthNumber(event.target.value)
                  }
                  className="input-box-double"
                />
                <div style={{ marginLeft: "4vw", marginRight: "4vw" }}>X</div>
                <input
                  type="number"
                  id="number-lenght-input"
                  value={selectedLenghtNumber}
                  onChange={(event) =>
                    setSelectedLenghtNumber(event.target.value)
                  }
                  className="input-box-double"
                />
              </div>
            )}
            {selectedUnitType === "Comprimento X Diâmetro" && (
              <div style={{ display: "flex", margin: "0 auto" }}>
                <input
                  type="number"
                  id="number-lenght-input"
                  value={selectedLenghtNumber}
                  onChange={(event) =>
                    setSelectedLenghtNumber(event.target.value)
                  }
                  className="input-box-double"
                />
                <div style={{ marginLeft: "4vw", marginRight: "4vw" }}>X</div>
                <input
                  type="number"
                  id="number-diam-input"
                  value={selectedDiamNumber}
                  onChange={(event) =>
                    setSelectedDiamNumber(event.target.value)
                  }
                  className="input-box-double"
                />
              </div>
            )}
            {!(selectedUnitType === "Largura X Comprimento") &&
              !(selectedUnitType === "Comprimento X Diâmetro") && (
                <div style={{ display: "flex", margin: "0 auto" }}>
                  <input
                    type="number"
                    id="number-input"
                    value={selectedNumber}
                    onChange={(event) => setSelectedNumber(event.target.value)}
                    className="input-box"
                  />
                </div>
              )}
          </div>
        )}

        {!showInputs && (
          <div className="centered-container">
            <label htmlFor="color-selector" className="form-label">
              Insira a cor (se houver):
            </label>
            <input
              type="text"
              id="color-input"
              value={selectedColor}
              onChange={(event) => setSelectedColor(event.target.value)}
              className="input-box"
            />
          </div>
        )}

        {!showInputs && (
          <div className="centered-container">
            <label htmlFor="model-selector" className="form-label">
              Insira o modelo (se houver):
            </label>
            <input
              type="text"
              id="model-input"
              value={selectedModel}
              onChange={(event) => setSelectedModel(event.target.value)}
              className="input-box"
            />
          </div>
        )}

        {!showInputs && (
          <div className="centered-container">
            <label htmlFor="brand-selector" className="form-label">
              Selecione a marca (se houver):
            </label>
            <div className="select-and-button-container">
              <select
                id="brand-selector"
                onChange={handleBrandChange}
                value={selectedBrand}
                className="select-box-brand"
              >
                <option value="">Marca</option>
                {brands.map((brand) => (
                  <option key={brand} value={brand}>
                    {brand}
                  </option>
                ))}
              </select>

              <button
                onClick={handleAddBrandClick}
                className="button-style-brand"
              >
                Adicionar nova marca
              </button>
            </div>

            {!showInputs && showBrandForm && (
              <div
                className="centered-container"
                style={{
                  marginTop: "1.5vh",
                }}
              >
                <input
                  type="text"
                  value={newBrand}
                  onChange={handleNewBrandChange}
                  className="input-box"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={handleSaveBrandClick}
                    className="button-style"
                  >
                    Salvar nova marca
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="centered-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1vh",
            }}
          >
            {buttonAddDisabled && (
              <button onClick={handleButtonClick} className="button-style-send">
                Adicionar novas atribuições
              </button>
            )}
            {showInputs && (
              <button
                onClick={handleCancelButtonClick}
                className="button-style"
              >
                Cancelar
              </button>
            )}
          </div>
          {showInputs && (
            <div className="centered-container">
              <ShowInput
                onChange={handleDepartmentChange}
                selectedInfo={selectedDepartment}
                selectBoxId="department-selector"
                selectBoxLabel="Departamento:"
              />

              <ShowInput
                onChange={handleDescriptionChange}
                selectedInfo={selectedDescription}
                selectBoxId="description-selector"
                selectBoxLabel="Descrição:"
              />

              <ShowInput
                onChange={handleCharacteristicChange}
                selectedInfo={selectedCharacteristic}
                selectBoxId="characteristic-selector"
                selectBoxLabel="Características:"
              />

              <div className="centered-container">
                <label htmlFor="type-selector" className="form-label">
                  Selecione o tipo de unidade:
                </label>
                <ShowSelect
                  handleDepartmentChange={handleUnitTypeChange}
                  selectedDepartment={selectedUnitType}
                  selectBoxId="type-selector"
                  selectBoxClassName="select-box"
                  selectBoxOptions={characteristics}
                />
              </div>

              {selectedUnitType && (
                <div className="centered-container">
                  <label htmlFor="type-selector" className="form-label">
                    Selecione a métrica:
                  </label>
                  <ShowSelect
                    handleDepartmentChange={handleUnitChange}
                    selectedDepartment={selectedUnit}
                    selectBoxId="type-selector"
                    selectBoxClassName="select-box"
                    selectBoxOptions={getUnits()}
                  />
                </div>
              )}

              {selectedUnit && (
                <div className="centered-container">
                  <label htmlFor="number-selector" className="form-label">
                    Insira o valor: <InfoButtonDimen />
                  </label>
                  {selectedUnitType === "Largura X Comprimento" && (
                    <div style={{ display: "flex", margin: "0 auto" }}>
                      <input
                        type="number"
                        id="number-width-input"
                        value={selectedWidthNumber}
                        onChange={(event) =>
                          setSelectedWidthNumber(event.target.value)
                        }
                        className="input-box-double"
                      />
                      <div style={{ marginLeft: "4vw", marginRight: "4vw" }}>
                        X
                      </div>
                      <input
                        type="number"
                        id="number-lenght-input"
                        value={selectedLenghtNumber}
                        onChange={(event) =>
                          setSelectedLenghtNumber(event.target.value)
                        }
                        className="input-box-double"
                      />
                    </div>
                  )}
                  {!(selectedUnitType === "Largura X Comprimento") && (
                    <div style={{ display: "flex", margin: "0 auto" }}>
                      <input
                        type="number"
                        id="number-input"
                        value={selectedNumber}
                        onChange={(event) =>
                          setSelectedNumber(event.target.value)
                        }
                        className="input-box"
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="centered-container">
                <label htmlFor="color-selector" className="form-label">
                  Cor (se houver):
                </label>
                <input
                  type="text"
                  id="color-input"
                  value={selectedColor}
                  onChange={(event) => setSelectedColor(event.target.value)}
                  className="input-box"
                />
              </div>

              <div className="centered-container">
                <label htmlFor="model-selector" className="form-label">
                  Modelo (se houver):
                </label>
                <input
                  type="text"
                  id="model-input"
                  value={selectedModel}
                  onChange={(event) => setSelectedModel(event.target.value)}
                  className="input-box"
                />
              </div>

              <ShowInput
                onChange={handleBrandChange}
                selectedInfo={selectedBrand}
                selectBoxId="brand-selector"
                selectBoxLabel="Marca (se houver):"
              />
            </div>
          )}
        </div>
        <div className="centered-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2vh",
            }}
          >
            <button
              className="button-style-send"
              type="submit"
              onClick={handleSubmit}
              disabled={buttonDisabled}
            >
              Enviar classificação
            </button>
            <Modal
              isOpen={modalIsOpen}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.75)",
                  zIndex: "1000",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  border: "none",
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                  padding: "2rem",
                  width: "15vw",
                  height: "15vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <h2>Insumo classificado</h2>
              <button
                onClick={() => setModalIsOpen(false)}
                className="button-style-lower"
              >
                Fechar
              </button>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
